<template>
    <div v-if="data" class="BarChart">
    <CChartHorizontalBar
        :datasets="[
          {
            data: chartData,
            backgroundColor:'#73A8ED',
          }
        ]"
        :options="{
          responsive: true,
          legend: {
            display: false,
          }
        }"
      />
    </div>
</template>

<script>
import { CChartHorizontalBar } from "@coreui/vue-chartjs";

export default {
    props: ["data"],
    components: {
        CChartHorizontalBar
    },
    data () {
        return {

        }
    },
    computed: {
        chartData() {
            return Object.values(this.data);
        }
    }
}
</script>

<style lang="scss" scoped>

</style>