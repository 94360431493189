<template>
  <div class="Card" :style="{ backgroundColor: color }">

    <h2 class="Card__Title">{{title}}</h2>
    <div class="Card__Content">
      <div class="Card__Content__Chart">
        <CChartBar
      :datasets="[
        {
          data: statsData,
          backgroundColor: '#FFFFFF',
          label: title,
          color: '#FFFFFF',
        },
      ]"
      :labels="statsLabels"
      :options="{
    legend: {
        display: false
    },
      
        maintainAspectRatio: true,
        scales: {
          yAxes: [
            {
              display: false,
              ticks: {
                fontColor: '#FFFFFF',
              },
            },
          ],
          xAxes: [
            {
              display: false,
              ticks: {
                fontColor: '#FFFFFF',
              },
            },
          ],
        },
      }"
        />
      </div>
    <h3 class="Card__Content__Number">{{number}}</h3>
  </div>

  <hr style="border-color: #FFF;">

  <div class="Card__Footer">
    <h6>{{footerText}}</h6>
    <h6></h6>
  </div>
  </div>
</template>

<script>
import { CChartBar } from "@coreui/vue-chartjs";

export default {
  props: ["data", "color", "title", "number", "footerText"],
  components: {
    CChartBar,
  },

  data() {
    return {};
  },
  computed: {
    statsData() {
      return Object.values(this.data);
    },
    statsLabels() {
      return Object.keys(this.data);
    },
  },
};
</script>

<style lang="scss" scoped>
.Card {
  width: 100% !important;
box-shadow: 2px 0px 39px -12px rgba(0,0,0,0.52);
  padding: 20px;
  width: 330px;
  height: 230px;
  &__Title {
    font-size: 1.4rem;
    color: #FFF;
  }
  &__Content {
    display: flex;
      justify-content: space-between;
      align-items: center;
    &__Chart {
      margin-top: 20px;
      width: 100px;
      height: auto;
    }
    &__Number {
      font-size: 2rem;
      color: #FFF;
    }
  }
  &__Footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #FFF;
  }
}
</style>
