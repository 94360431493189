<template>
  <div>
    <CChartDoughnut
      :datasets="[
        {
          data: chartData,
          backgroundColor: colors,
        },
      ]"
      :labels="chartLabels"
      :options="{
        responsive: true,
        legend: {
          display: Object.keys(complaints_pie).length > 3 ? false : true,
        },
      }"
    />
  </div>
</template>

<script>
import { CChartDoughnut } from "@coreui/vue-chartjs";

export default {
  props: ["complaints_pie"],
  components: {
    CChartDoughnut,
  },
  data() {
    return {};
  },
  computed: {
    chartLabels() {
      const arr = [];
      for (const property in this.complaints_pie) {
        arr.push(property);
      }
      return arr;
    },
    chartData() {
      const arr = [];
      for (const property in this.complaints_pie) {
        arr.push(this.complaints_pie[property]);
      }
      return arr;
    },
    colors() {
      const arr = [];
      for (const key in this.complaints_pie) {
        arr.push(`#${Math.floor(Math.random() * 16777215).toString(16)}`);
      }
      return arr;
    },
  },
};
</script>

<style lang="scss" scoped>
canvas {
  width: 1000px !important;
  height: 600px !important;
}
</style>
