var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.data)?_c('div',{staticClass:"BarChart"},[_c('CChartHorizontalBar',{attrs:{"datasets":[
      {
        data: _vm.chartData,
        backgroundColor:'#73A8ED',
      }
    ],"options":{
      responsive: true,
      legend: {
        display: false,
      }
    }}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }