var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"Card",style:({ backgroundColor: _vm.color })},[_c('h2',{staticClass:"Card__Title"},[_vm._v(_vm._s(_vm.title))]),_c('div',{staticClass:"Card__Content"},[_c('div',{staticClass:"Card__Content__Chart"},[_c('CChartBar',{attrs:{"datasets":[
      {
        data: _vm.statsData,
        backgroundColor: '#FFFFFF',
        label: _vm.title,
        color: '#FFFFFF',
      },
    ],"labels":_vm.statsLabels,"options":{
  legend: {
      display: false
  },
    
      maintainAspectRatio: true,
      scales: {
        yAxes: [
          {
            display: false,
            ticks: {
              fontColor: '#FFFFFF',
            },
          },
        ],
        xAxes: [
          {
            display: false,
            ticks: {
              fontColor: '#FFFFFF',
            },
          },
        ],
      },
    }}})],1),_c('h3',{staticClass:"Card__Content__Number"},[_vm._v(_vm._s(_vm.number))])]),_c('hr',{staticStyle:{"border-color":"#FFF"}}),_c('div',{staticClass:"Card__Footer"},[_c('h6',[_vm._v(_vm._s(_vm.footerText))]),_c('h6')])])
}
var staticRenderFns = []

export { render, staticRenderFns }