var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('CChartDoughnut',{attrs:{"datasets":[
      {
        data: _vm.chartData,
        backgroundColor: _vm.colors,
      },
    ],"labels":_vm.chartLabels,"options":{
      responsive: true,
      legend: {
        display: Object.keys(_vm.complaints_pie).length > 3 ? false : true,
      },
    }}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }